<template>
  <div>
    <div class="registration">
      <div class="registration-top">
        <div class="registration-logo">
          <a href="/"><img src="../assets/images/logo.png" alt="车学堂"/></a>
        </div>
        <!-- <div class="go-login"><a href="#">已有账号点击登录</a></div>-->
      </div>
      <el-steps
        :active="active"
        finish-status="success"
        align-center
        class="step-box"
      >
        <el-step title="快速注册"></el-step>
        <el-step title="付费"></el-step>
        <el-step title="注册完成"></el-step>
      </el-steps>
      <div class="register-step">
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick">-->
        <el-tabs v-model="activeName">
          <el-tab-pane name="first">
            <el-form
              ref="formRegister"
              :model="formRegister"
              label-width="100px"
              :rules="rules"
            >
              <el-form-item label="身份证号：" prop="idcard">
                <el-input
                  v-model="formRegister.idcard"
                  maxlength="18"
                ></el-input>
              </el-form-item>
              <el-form-item label="姓    名：" prop="realname">
                <el-input
                  v-model="formRegister.realname"
                  maxlength="20"
                ></el-input>
              </el-form-item>
              <el-form-item label="项    目：">
                车学堂
              </el-form-item>
              <el-form-item label="类    别：" prop="licence_id">
                <p>
                  请按照您的继续教育类别<b>正确、完整</b>的选择所有培训类别，如类别<b>少选、错选</b>，导致的培训<b>学时无效</b>由<b
                    >本人负责</b
                  >
                </p>
                <el-checkbox-group
                  v-model="licence_type"
                  @change="getLicence_id"
                >
                  <el-checkbox
                    v-for="item in licence"
                    :label="item.type"
                    :key="item.type"
                    >{{ item.licence_title }}</el-checkbox
                  >
                </el-checkbox-group>
                <p>
                  您选择的类型将按照最高类别<b>({{ licence_title }})</b>进行学习
                  培训完成后请用电脑登陆系统,<b>自行打印</b>“报名表”、“合格证明”，凭打印资料到您的从业资格证<b>所属辖区</b>办理相关业务
                </p>
              </el-form-item>
              <el-form-item label="联系电话：" prop="phone">
                <el-input
                  v-model="formRegister.phone"
                  maxlength="11"
                ></el-input>
              </el-form-item>
              <el-form-item label="地    址：" prop="address">
                <el-input
                  v-model="formRegister.address"
                  maxlength="60"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码：" prop="code">
                <el-input
                  v-model="formRegister.code"
                  style="width:40%"
                ></el-input>
                <div class="code-img" @click="refreshCode">
                  <!--验证码-->
                  <Identify :identifyCode="identifyCode"></Identify>
                </div>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="agreement" style="margin-right:8px"
                  >&nbsp;</el-checkbox
                >
                我已阅读并接受
                <a
                  @click="showAgreement = true"
                  style="color:#f00;padding:0 5px"
                  >用户服务协议</a
                >
              </el-form-item>
            </el-form>
            <el-button
              @click="handleRegister('formRegister')"
              class="register-box"
              >注册</el-button
            >
          </el-tab-pane>
          <el-tab-pane name="second">
            <div class="confirm-info">
              <h2>确认信息</h2>
              <ul>
                <li>
                  <h4>姓名：</h4>
                  <span>{{ this.orderinfo['real_name'] }}</span>
                </li>
                <li>
                  <h4>身份证号：</h4>
                  <span>{{ this.orderinfo['idcard'] }}</span>
                </li>
                <li>
                  <h4>项目：</h4>
                  <span>继续教育</span>
                </li>
                <li>
                  <h4>类 别：</h4>
                  <span>{{ this.orderinfo['licence_title'] }}</span>
                </li>
                <li>
                  <h4>联系电话：</h4>
                  <span>{{ this.phone }}</span>
                </li>
                <li>
                  <h4>发证机关：</h4>
                  <span>人民交通出版社股份有限公司</span>
                </li>
                <li style="margin-top:10px">
                  <b
                    >您这次总共课时费用为￥{{
                      this.orderinfo['total_fee'] / 10 / 10
                    }}</b
                  >
                </li>
                <li class="pay-box">
                  <b>请选择支付方式:</b>
                  <el-radio-group v-model="type">
                    <el-radio :label="1">微信</el-radio>
                    <img src="../assets/images/weixin.jpg" alt="" />
                    <el-radio :label="2">支付宝</el-radio>
                    <img src="../assets/images/payment_zfb.jpg" alt="" />
                  </el-radio-group>
                </li>
              </ul>
            </div>
            <div class="confirm-info-btn">
              <el-button type="primary" @click="confirmPay = true"
                >去支付</el-button
              >
              <el-button @click="prev">上一步</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane name="third">
            <div class="accomplish-box">
              <span class="icon-right-coarse"></span>
              <h1>注册成功</h1>
              <p>感谢你对本公司的产品以及服务的不懈支持</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="confirmPay"
      width="90%"
      class="conf-message"
    >
      <!-- <p><b>报名驾校:</b>天津国交智慧信息技术有限公司</p>-->
      <p><b>类别:</b>{{ this.orderinfo['licence_title'] }}</p>
      <p style="color:#F00">
        请仔细核对报名类别，确认无误后再点击确定，点击取消即可修改。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmPay = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="网上支付提示"
      :visible.sync="payVisible"
      width="90%"
      class="conf-message"
    >
      <p>1.支付完成前，请不要关闭此支付验证窗口。</p>
      <p>2.支付完成后，请根据您支付的情况点击下面按钮。</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleFinished">已完成支付</el-button>
        <el-button type="primary" @click="payVisible = false"
          >重新支付</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      width="80%"
      title="用户购买协议"
      :visible.sync="showAgreement"
      append-to-body
    >
      <div class="agreement">
        <p>
          用户在购买本次车学堂收费网络课程之前，请仔细阅读本协议条款。用户应当同意本协议的全部条款，用户完成支付即表示完全接受本协议项下的全部条款。
        </p>
        <p>
          1.除了用户购买本次收费网络课程并私人观看外，对于任何其他未明确授权给用户的收费网络课程及其他服务的权利、收益，由本平台或相关权利人所有。
        </p>
        <p>
          2.用户应妥善保存账户注册时所填写的信息。用户账户注册时所填写的信息将成为平台验证的唯一凭证。因用户账户注册信息丢失造成不能正常登录使用的，本平台不承担任何法律责任。
        </p>
        <p>
          3.用户应自行负责保管、使用、维护其在本平台的账户及账户密码。用户应对其账户及密码采取必要和有效的记忆和保密措施，对于非因本平台原因导致用户账户及密码遗忘、泄漏等所造成的用户损失，本平台无须承担与此有关的任何责任。用户发现其账户、密码遗忘、丢失后，应及时与本平台取得联系，本平台可视情况协助用户恢复其权益。
        </p>
        <p>
          4.本平台收费网络课程的价格另行标明，用户点击“同意”本协议，即视为用户认可本次收费网络课程标明的价格；用户支付成功后，该项服务即时生效。
        </p>
        <p>
          5.如果用户同意购买本次收费网络课程，支付成功后本协议将立即生效，用户可即时观看或在有效期内另择方便时段观看本次收费网络课程视频。如果支付失败，本协议将不发生效力。用户将无权观看本次收费网络课程视频。
        </p>
        <p>
          6.本平台有权根据包括但不限于国家最新颁布或修订的相关法律法规、标准等，对网络课程进行相应的修改、更新。该等修改、更新对用户并无任何不利，不需要提前通知用户或征得用户同意，本平台视为用户接受并认可相关修改、更新行为。
        </p>
        <p>
          7.因用户将个人账户及密码告知他人或与他人共享注册帐户，由此导致任何个人资料泄露的，本平台不负任何责任。
        </p>
        <p>
          8.用户购买收费网络课程，只可以私人观看，不得用于商业、盈利等用途。用户同意不得对授课内容录音录像，不得将网络课程内容复制、销售、出租或授权给任何第三方。如果本平台发现用户存在上述行为，本平台将有权停止用户对本次课程的观看，该用户已支付的费用将不予退还。用户在本平台使用的账户不得出售。如果本平台发现用户擅自出售平台账户，本平台将有权终止该账户的使用，该用户已支付的费用将不予退还。
        </p>
        <p>
          9.用户购买的收费网络课程有一定的有效期。超过有效期，用户将无法观看网络课程视频。用户若想获得继续观看的权限，需再次购买并支付费用。
        </p>
        <p>
          10.用户在同意购买并支付成功后至有效期内首次观看课程前，可以单方解除本协议并申请退款。否则，用户一旦产生了观看记录或者虽未产生观看记录但已过有效期，则不能申请退款，请知悉。
        </p>
        <p>
          11.如果用户在同意购买并支付费用时，使用支付宝或微信形式付款，则用户根据前条申请退款时，本平台将视用户付款方式将相关费用退至用户的支付宝或微信账户内。由于支付宝和微信会产生手续费，该等手续费应由用户承担，所以退费时会被扣除1%的费用。
        </p>
      </div>
      <div class="confirm-box">
        <el-button type="info" disabled v-if="wait > 0"
          >同意以上协议<span> ({{ wait }}) </span></el-button
        >
        <el-button type="primary" @click="agree" v-else>同意以上协议</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="微信支付"
      :visible.sync="wxpaydialog"
      :fullscreen="true"
      center
      :before-close="handleClosewx"
    >
      <div class="wechat-pay">
        <h2>
          支付金额：
          <span>{{ this.orderinfo.total_fee / 100 }}</span>
          元
        </h2>
        <div>
          <img :src="this.wximage" />
          <p>请微信扫码支付</p>
        </div>
      </div>
    </el-dialog>
    <div v-show="showTip" id="weixinTip" ref="weixin" class="wxtip">
      <img src="../assets/images/meizi.png" />
    </div>
  </div>
</template>
<script>
import Identify from '../components/identify'
import { register } from '../api/login'
import { createorder, confirmorder, selectorder } from '../api/order'
import {
  setResult,
  getResult,
  removeIsPay,
  setIsPay,
  removeResult
} from '../utils/auth'
const steps = ['first', 'second', 'third']
export default {
  components: { Identify },
  data() {
    let checkPhone = (rule, value, callback) => {
      const regPhone = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (regPhone.test(value)) {
        return callback()
      }
      callback(new Error('请输入合法的手机号'))
    }
    let chinaIdentityValid = (rule, value, callback) => {
      if (!value) {
        return callback()
      }
      const regIdentify = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/
      if (!value || !regIdentify.test(value)) {
        callback(new Error('身份证号格式错误'))
      }
      callback()
    }
    return {
      wait: 6,
      agreement: false,
      active: 0,
      showAgreement: true,
      confirmPay: false,
      payVisible: false,
      showTip: false,
      identifyCodes: '1234567890',
      identifyCode: '',
      formRegister: {
        idcard: '',
        phone: '',
        licence_id: '',
        licence_title: '请选择培训类别',
        appid: '40000129',
        province_id: 120000,
        city_id: 120101,
        realname: '',
        address: '',
        jiaxiao_id: 0,
        checked: true,
        type: 2,
        code: '',
        user_id: 0,
        cata_id: 0
      },
      code: '',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        desc: ''
      },
      licence: [
        { licence_title: '道路货物运输驾驶员', licence_id: 9, type: 1 },
        { licence_title: '道路旅客运输驾驶员', licence_id: 8, type: 2 },
        { licence_title: '道路危险货物运输驾驶员', licence_id: 21, type: 3 }
      ],
      licence_type: [],
      pay_type: 1,
      desc: '',
      data: '',
      phone: '',
      orderinfo: [],
      mid: 34,
      type: 1,
      wxpaydialog: false,
      wximage: '',
      selTimer: 0,
      start: 120,
      end: 3,
      licence_title: '',

      rules: {
        idcard: [
          { required: true, message: '请输入身份证', trigger: 'blur' },
          { validator: chinaIdentityValid, trigger: 'blur' }
        ],
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 50, message: '请正确填写姓名', trigger: 'blur' }
        ],
        licence_id: [
          { required: true, message: '请选择类别', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          { min: 2, max: 50, message: '请正确地址', trigger: 'blur' }
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  created() {
    var ua = navigator.userAgent.toLowerCase()
    var isWeixin = ua.indexOf('micromessenger') != -1
    if (isWeixin) {
      //如果是微信环境，就弹出遮罩层
      this.showTip = true
      this.showAgreement = false
    }
  },
  mounted() {
    const timer = setInterval(() => {
      if (this.wait > 0) {
        this.wait--
      } else {
        clearInterval(timer)
      }
    }, 1000)
    this.refreshCode()
    removeResult()
  },
  methods: {
    next() {
      if (this.active < steps.length - 1) {
        this.active++
      }
    },
    getCode() {
      // 非静默授权，第一次有弹框
      this.data = this.$route.query
      // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      const local = window.location.href
      const appid = '' //公众号appid
      if (this.data.code == null || this.data.code === '') {
        window.location.href =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
          +'&redirect_uri=' +
          encodeURIComponent(local) +
          '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
      }
    },
    prev() {
      if (this.active > 0) {
        this.active--
      }
      this.formRegister.licence_id = ''
      this.formRegister.licence_title = ''
    },
    // 重置验证码
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 5)
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    handleRegister(formName) {
      if (this.agreement != true) {
        this.showAgreement = true
        this.wait = 6
      }

      this.formRegister.register_type = 1
      this.formRegister.term = 1
      this.formRegister.register_ip = returnCitySN['cip']
      //this.getLicence_id()

      if (this.formRegister.licence_id === '') {
        this.getLicence_id()
        this.$message.error('请选车型')
        return
      }
      if (this.formRegister.licence_title === '') {
        this.$message.error('请选车型')
        return
      }
      // console.log(this.formRegister)
      removeResult()
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.formRegister.code !== this.identifyCode.toLowerCase()) {
            this.$message.error('请填写正确验证码')
            return
          }

          register(this.formRegister).then(res => {
            if (res.code === 0) {
              setResult(res.data)
              this.formRegister.user_id = res.data.userid
              this.formRegister.cata_id = res.data.cata_id
              this.active++
              this.createorder()
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })
        }
      })
    },
    getLicence_id() {
      if (this.licence_type == null || this.licence_type.length == 0) {
        return
      }
      var licence_type = this.licence_type
      licence_type.sort()
      var type = licence_type[licence_type.length - 1]

      this.licence.forEach(item => {
        if (type == item.type) {
          this.formRegister.licence_id = item.licence_id
          this.licence_title = item.licence_title
          this.formRegister.licence_title = licence_type.join(',')
        }
      })
    },
    handleConfirm() {
      this.confirmPay = false
      //this.payVisible = true
      for (let i = 0; i < this.orderinfo['paytype'].length; i++) {
        if (this.orderinfo['paytype'][i]['paytype'] == this.type) {
          this.mid = this.orderinfo['paytype'][i]['mid']
        }
      }
      let params = {
        out_trade_no: this.orderinfo.out_trade_no,
        paytype: this.type,
        mid: this.mid,
        platid: 2,
        returnUrl: 'https://local-www.chexuetang.cn/orderresult'
      }
      confirmorder(params).then(res => {
        if (res.code === 0) {
          this.selOrder()
          if (this.type === 1) {
            //微信var
            var ua = navigator.userAgent.toLowerCase()
            var isWeixin = ua.indexOf('micromessenger') != -1
            if (isWeixin) {
              //微信浏览器
              alert('微信浏览器')
            } else {
              //jsapi
              var url =
                params.returnUrl + '?orderno=' + this.orderinfo.out_trade_no
              url = res.result + '&redirect_url=' + encodeURIComponent(url)
              window.location.href = url
            }
          } else if (this.type === 2) {
            //支付宝
            /*let routerData = this.$router.resolve({
              path: '/applyText',
              query: {
                htmls: res.result
              }
            })
            this.htmls = res.result
            window.location.href = routerData.href */
            const div = document.createElement('div')
            div.id = 'alipay'
            div.innerHTML = res.result
            document.body.appendChild(div)
            document.querySelector('#alipay').children[0].submit() // 执行后会唤起支付宝
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },

    handleFinished() {
      this.active++
    },
    //同意
    agree() {
      this.agreement = true
      this.showAgreement = false
    },
    //生成订单
    createorder() {
      createorder({ url: 'https://www.chexuetang.cn/#/catalogs' }).then(res => {
        if (res.code === 0) {
          this.orderinfo = res.result
          this.phone = JSON.parse(getResult()).phone
        }
      })
    },
    handleClosewx(done) {
      this.active = 1
      clearInterval(this.selTimer)
      this.wxpaydialog = false
    },
    //查询订单
    selOrder() {
      this.selTimer = setInterval(() => {
        if (this.start <= this.end) {
          clearInterval(this.selTimer)
          this.$message({
            message: '支付超时！',
            type: 'error',
            onClose: () => {
              this.wxpaydialog = false
            }
          })
        }
        let params = {
          out_trade_no: this.orderinfo.out_trade_no,
          platid: 2
        }
        selectorder(params).then(res => {
          this.start = this.start - this.end
          if (res.code == 0) {
            this.wxpaydialog = false
            this.active = 2
            ;(this.activeName = steps[this.active]),
              clearInterval(this.selTimer)
            removeIsPay()
            setIsPay('2')
          } else if (res.code == 1) {
          } else {
            clearInterval(this.selTimer)
            this.$message({
              message: '系统错误！',
              type: 'error'
            })
          }
        })
      }, this.end * 1000)
    }
  },
  computed: {
    activeName: {
      get() {
        return steps[this.active]
      },
      set(v) {}
    }
  }
}
</script>
<style lang="less" scoped>
.wxtip {
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  img {
    width: 100%;
  }
}
.wxtip-txt {
  margin-top: 107px;
  color: #fff;
  font-size: 20px;
  line-height: 1.8;
}
</style>
